<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2022-06-01 16:50 -->
<!-- Description: -->
<template>
  <form @submit.prevent="save()">
    <div class="mb-2">
      <label for>Nombre</label>
      <input
        ref="elInputName"
        type="text"
        class="form-control"
        v-model="name"
        maxlength="255"
      />
      <app-form-errors :errors="errors.name"></app-form-errors>
    </div>
    <div class="mb-2">
      <label for>Tipo de Producto</label>
      <SelectType required v-model="type_id"></SelectType>
      <app-form-errors :errors="errors.type_id"></app-form-errors>
    </div>

    <div class="mb-2">
      <label for>Area</label>
      <SelectArea v-model="area_id"></SelectArea>
    </div>
    <div class="mb-2">
      <label for>Categoria</label>
      <SelectCategorie ref="setcat" v-model="categorie_name"></SelectCategorie>
    </div>
    <div class="mb-2">
      <label for>Fuente de Ingreso</label>
      <SelectSource ref="setsou" v-model="source_id"></SelectSource>
    </div>
    <div class="mb-2">
      <label for>Precio Unitario</label>
      <app-input-amount v-model.number="unit_price"></app-input-amount>
      <app-control-errors :errors="errors.unit_price"></app-control-errors>
    </div>
    <!-- <div class="mb-2"> -->
    <!--   <label for>Codigo</label> -->
    <!--   <input type="text" class="form-control" v-model="code" required /> -->
    <!-- </div> -->
    <!-- <div class="mb-2"> -->
    <!--   <label for>Marca</label> -->
    <!--   <SelectBrand ref="elSelectBrand" v-model="brand_name"></SelectBrand> -->
    <!-- </div> -->
    <!-- <div class="mb-2"> -->
    <!--   <label for>Unidad de Medida</label> -->
    <!--   <SelectMeasurement -->
    <!--     ref="selMea" -->
    <!--     v-model="measurement_name" -->
    <!--   ></SelectMeasurement> -->
    <!-- </div> -->
    <!-- <div class="mb-2"> -->
    <!--   <label for>Unidades x paquete</label> -->
    <!--   <app-input-number -->
    <!--     v-model.number="units_by_pack" -->
    <!--     step="1" -->
    <!--   ></app-input-number> -->
    <!-- </div> -->
    <!-- <div class="mb-2"> -->
    <!--   <label for="">Imagen de referencia</label> -->
    <!--   <img ref="elImgPreview" class="product_img mb-2" /> -->
    <!--   <form ref="elFormFile"> -->
    <!--     <input -->
    <!--       type="file" -->
    <!--       class="w-100" -->
    <!--       ref="elInputFile" -->
    <!--       accept="image/*" -->
    <!--       @change="changeImagePreviewHandler()" -->
    <!--     /> -->
    <!--   </form> -->
    <!-- </div> -->
    <app-button-submit
      :loading="loading"
      :disabled="errors"
    ></app-button-submit>
  </form>

  <!-- <div class="row"> -->
  <!--   <div class="col"> -->
  <!--     <app-table-control v-model="adds" :disabled="disabled"> -->
  <!--       <tr> -->
  <!--         <th class="text-center" colspan="2">DATOS ADICIONALES</th> -->
  <!--       </tr> -->
  <!--     </app-table-control> -->
  <!--   </div> -->
  <!-- </div> -->

  <!-- <form @submit.prevent="save()"> -->
  <!-- <div class="form-group d-none" :class="{ 'd-flex': file_id }"> -->
  <!--   <img -->
  <!--     :src="file_id | fileUrl" -->
  <!--     class="app_image mx-auto" -->
  <!--     alt="imagen de referencia" -->
  <!--   /> -->
  <!-- </div> -->

  <!-- <div class="form-group"> -->
  <!--   <label for>Imagen de referencia</label> -->
  <!-- <app-input-file id="customFile1" ref="if" @change="files = $event"></app-input-file> -->
  <!-- <br /> -->
  <!-- <input -->
  <!--   type="file" -->
  <!--   accept="image/*" -->
  <!--   @change="files = $event.target.files" -->
  <!--   ref="inFile" -->
  <!-- /> -->
  <!-- <div class="custom-file">
        <input type="file" class="custom-file-input" id="productImageId" required @change="files = $event">
        <label class="custom-file-label" for="productImageId">Seleccionar una imagen</label>
        <div class="invalid-feedback">Example invalid custom file feedback</div>
      </div>-->
  <!-- </div> -->

  <!-- <div v-if="files.length > 0" class="form-group">
      <span v-for="(l, index) in files" :key="index">{{ l.name }} </span>
    </div>-->
  <!-- <div class="form-group">
      <button class="btn btn-primary">Guardar</button>
    </div>-->
  <!-- <app-button-submit @click="save()" :disabled="errors"></app-button-submit> -->
  <!-- </form> -->
</template>
<script>
// import { GeneralService } from "../../general-module/GeneralService.js";
import SelectType from "./SelectType.vue";
import SelectCategorie from "../../general-module/categories/InputSelect";
import SelectArea from "../../admin-module/areas/Select.vue";
import SelectSource from "../../admin-module/sources/Select.vue";
// import SelectBrand from "../../general-module/brands/Select";
// import SelectMeasurement from "../../general-module/measurements/Select";
import { StoreService } from "../StoreService";
import validator from "../../utils/validator.js";
// import fileUrl from "../../utils/fileUrl.js";
// import {
//   compressToUpload,
//   defaultCompressOptions
// } from "../../utils/compress-images.js";
// import ImageCompressor from "js-image-compressor";
// import Compress from "compress.js";
//import * as Compress from "compress.js"
// const formRules = {
//   type_id: { presence: { message: "Requerido" } },
//   name: { presence: { message: "Requerido" } },
//   code: { presence: { message: "Requerido" } },
//   // categorie_id: { presence: { message: "Requerido" } },
//   money_code: { presence: { message: "Requerido" } },
//   unit_price: { presence: { message: "Requerido" } }
// };

const formRules = {
  name: { presence: { allowEmpty: false } },
  type_id: { presence: { allowEmpty: false } },
  unit_price: { presence: { allowEmpty: false } }
};

export default {
  components: {
    SelectType,
    SelectCategorie,
    SelectArea,
    SelectSource
    // SelectBrand,
    // SelectMeasurement
  },

  // directives
  // filters

  props: {
    category: {
      default: null
    },
    disabled: {
      default: false
    },
    areaId: {
      default: null
    }
  },

  data: () => ({
    id: null,
    type_id: 2,
    name: "",
    code: "",
    categorie_name: "",
    unit_price: "",
    brand_name: "",
    measurement_name: "",
    units_by_pack: "",
    file_id: null,
    area_id: null,
    source_id: null,

    loading: false
  }),

  computed: {
    errors() {
      return validator(this.$data, formRules);
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    focus() {
      this.$refs.elInputName.focus();
    },
    reset() {
      this.loading = false;
      this.errorMessage = "";

      this.id = null;
      this.type_id =
        (this.$attrs.type_id && parseInt(this.$attrs.type_id)) || 1;
      this.name = "";
      this.code = "";
      this.categorie_name = this.category || "";
      this.unit_price = "";
      this.brand_name = "";
      this.measurement_name = "";
      this.units_by_pack = "1";
      this.file_id = null;

      // this.$refs.elFormFile.reset();
      // this.$refs.elImgPreview.src = "";
      // this.$refs.elSelectBrand.reset();
      // this.$forceUpdate();
      // this.$refs.inputMoney.setDefault();
      this.$refs.setcat.reset();
      this.$refs.setsou.reset();
      // this.$refs.selMea.reset();
      // this.files = [];
    },
    async save() {
      try {
        this.errorMessage = "";
        this.loading = true;
        // if (this.$refs.elInputFile.files.length > 0) {
        //   let filesCompressed = await compressToUpload(
        //     this.$refs.elInputFile.files,
        //     defaultCompressOptions
        //   );

        //   [this.file_id] = await GeneralService.uploadFiles(filesCompressed);
        // }

        let productSaved = await StoreService.saveProduct(this.$data);
        if (this.id == null) {
          productSaved.is_new = true;
        }
        this.$emit("submitted", productSaved);
        // if (this.id == null) {
        //   this.$emit("newRegister", productSaved);
        // }
      } catch (e) {
        if (e.response.data) {
          window.alert(e.response.data.message);
        } else {
          window.alert("Algo salio mal");
        }
      }
      this.loading = false;
    },
    setValue(product) {
      this.id = product.id;
      this.type_id = product.type_id;
      this.name = product.name;
      this.code = product.code;
      this.categorie_name = product.categorie_name;
      this.unit_price = product.unit_price;
      this.brand_name = product.brand_name;
      this.measurement_name = product.measurement_name;
      this.units_by_pack = product.units_by_pack;
      this.file_id = product.file_id;
      this.area_id = product.area_id;
      this.source_id = product.source_id;

      // if (this.file_id) {
      //   this.$refs.elImgPreview.src = fileUrl(this.file_id);
      // } else {
      //   this.$refs.elImgPreview.src = "";
      //   this.$refs.elFormFile.reset();
      // }

      this.$refs.setcat.setText(this.categorie_name);
      this.$refs.setsou.setValueFromId(this.source_id);
      // this.$refs.elSelectBrand.setText(this.brand_name);
      // this.$refs.selMea.setText(this.measurement_name);
    },
    loadReg(product) {
      this.loadReg(product);
    },
    changeImagePreviewHandler() {
      //console.log(this.$refs.elInputFile);

      if (
        this.$refs.elInputFile.files &&
        this.$refs.elInputFile.files.length > 0
      ) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.elImgPreview.src = e.target.result;
        };
        reader.readAsDataURL(this.$refs.elInputFile.files[0]);
      }

      // let [imageSource] =
      //this.$refs.elImgPreview.src = "hola";
    }
  }
};
</script>

<style scoped>
.product_img {
  display: block;
  width: 100%;
  max-width: 500px;
}
</style>
