<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Producto</label>
      <SelectProduct
        ref="selPro"
        :disabled="!!id"
        v-model="product_id"
      ></SelectProduct>
    </div>
    <div class="form-group">
      <label for>Estado</label>
      <SelectState :showLabels="true" v-model="status"></SelectState>
    </div>
    <div class="form-group">
      <label for>Estado de Evolucion</label>
      <SelectState :showLabels="true" v-model="status_target"></SelectState>
    </div>
    <div class="form-group">
      <app-checkbox v-model="discount_lab_cost"
        >Descontar de laboratorio</app-checkbox
      >
    </div>
    <app-button-submit></app-button-submit>
  </form>
</template>

<script>
import SelectProduct from "../../../store-module/products/Select";
import SelectState from "../menu/SelectState";
import { DentalClinicService } from "../../service";

export default {
  components: {
    SelectProduct,
    SelectState,
  },
  data: () => ({
    id: 0,
    status: 0,
    status_target: 0,
    product_id: 0,
    discount_lab_cost: false,
  }),
  methods: {
    loadReg(reg) {
      this.id = reg.id;
      this.status = reg.status;
      this.status_target = reg.status_target;
      this.product_id = reg.product_id;
      this.discount_lab_cost = reg.discount_lab_cost;
      this.$refs.selPro.setValueFromId(reg.product_id);
    },
    setValue(reg) {
      this.loadReg(reg);
    },
    reset() {
      this.id = 0;
      this.status = 0;
      this.status_target = 0;
      this.product_id = 0;
      this.discount_lab_cost = false;
      this.$refs.selPro.reset();
    },
    save() {
      DentalClinicService.saveProductStatus(this.$data).then((res) => {
        this.$emit("submitted", res);
      });
    },
  },
};
</script>

<style></style>
